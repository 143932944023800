.item_title {
  font-weight: bold;
  font-size: 13pt;
  text-align: justify;
  cursor: pointer;
}

@media (max-width: 800px) {
  .item_title {
    font-size: 12pt;
  }
}

.online_button{
  background-color: #1E90FF !important;
  color: white !important;
}

.online_button:hover{
  background-color: #4169E1 !important;
  color: white !important;
}