.fist_section {
  margin-top: -1.5rem;
}
.contact {
  h6 {
    margin-bottom: 0.5rem !important;
    margin-top: 0rem !important;
  }
  p {
    margin-bottom: 0.75rem !important;
  }
}
